<script setup lang="ts">
const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();

const { cartItems, totalPrice, isEmpty, shippingTotal, subtotal } = useCart();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { sessionContext } = useSessionContext();
const freeShippingThreshold =
  sessionContext.value?.salesChannel?.customFields
    ?.custom_storefront_global_free_shipping_threshold || 75;
</script>

<template>
  <LayoutSidebar :controller="props.controller" side="right" class="relative">
    <button
      type="button"
      class="text-dark absolute -left-14 top-28 hidden h-11 w-11 items-center justify-center rounded-full bg-white md:flex"
      @click="props.controller.close"
    >
      <span class="sr-only"> Close filter </span>
      <i aria-hidden class="icon i-custom-svg:cross block text-2xl" />
    </button>
    <aside class="flex h-[100dvh] h-screen flex-col px-4 py-6 sm:px-6">
      <div class="flex items-center justify-between">
        <h2 class="text-left text-3xl font-bold">{{ $t('cart.header') }}</h2>
        <button
          type="button"
          class="text-dark flex h-11 w-11 items-center justify-center rounded-full bg-white md:hidden"
          @click="props.controller.close"
        >
          <span class="sr-only"> Close filter </span>
          <i aria-hidden class="icon i-custom-svg:cross block text-2xl" />
        </button>
      </div>
      <ul
        v-if="!isEmpty"
        role="list"
        class="mt-3 grow divide-y divide-gray-200 overflow-y-auto border-t px-0"
      >
        <li v-for="cartItem in cartItems" :key="cartItem.id" class="flex py-6">
          <CheckoutCartItemQuick
            :cart-item="cartItem"
            has-delete-option
            has-quantity-selector
          />
        </li>
      </ul>
      <div
        v-if="!isEmpty"
        class="relative flex flex-col justify-between gap-3 justify-self-end"
      >
        <CheckoutPromotionCode button-classes="!font-medium" />
        <div class="flex justify-between text-2xl">
          <p class="font-bold">{{ $t('cart.subtotal') }}</p>
          <SharedPrice
            :value="totalPrice - shippingTotal"
            class="font-bold text-gray-900"
          />
        </div>
        <div class="flex justify-between">
          <p>{{ $t('cart.shippingEstimate') }}</p>
          <p v-if="shippingTotal < 1" class="uppercase">
            {{ $t('cart.freeShipping') }}
          </p>
          <SharedPrice v-else :value="shippingTotal" class="text-gray-900" />
        </div>
        <CheckoutShippingCalculator
          :current-price="subtotal"
          :price-limit="freeShippingThreshold"
        />
        <p class="text-left text-sm text-gray-500">
          {{ $t('cart.priceNotice') }}
        </p>
        <div class="flex flex-col gap-3 md:flex-row">
          <NuxtLink
            class="btn w-full px-2"
            :class="{
              'bg-gray': isEmpty,
              'hover:bg-gray': isEmpty,
            }"
            :to="formatLink(`/checkout/cart`)"
          >
            {{ $t('cart.viewCart') }}
          </NuxtLink>
          <NuxtLink
            class="btn btn-solid w-full px-2"
            :to="formatLink(isEmpty ? '' : '/checkout')"
          >
            {{ $t('cart.toCheckout') }}
          </NuxtLink>
        </div>
      </div>
      <div
        v-else
        class="flex h-full flex-col items-center justify-center gap-5"
      >
        <span class="text-2xl">
          {{ $t('cart.emptyCartLabel') }}
        </span>

        <button
          class="btn btn-solid text-white"
          @click="props.controller.close"
        >
          {{ $t('cart.discover') }}
        </button>
      </div>
    </aside>
  </LayoutSidebar>
</template>
